<template>
    <div v-if="connectId">
        <v-autocomplete
            ref="form"
            :items="items"
            v-model="uk"
            item-text="name"
            auto-select-first
            item-value="user_key"
            @focus="onFocus"
            label="选择连接"
            :disabled="disabled"
            :loading="!loaded"
            placeholder="默认"
            persistent-placeholder
        >
        </v-autocomplete>
    </div>
</template>

<script>
import { connectStatusAll, connectStatus } from "../../store/query";

export default {
    props: {
        info: Object
    },
    computed: {
        connectId () {
            return this.info.action.connectId || ''
        },
        uk: {
            get () {return this.info.args.connectUserKey || ''},
            set (v) {this.info.args.connectUserKey = v}
        },
        items () {
            let res = []
            for (const uk of this.userKeys) {
                let item = this.vuex_connects[this.connectId + (uk || '')]
                if (!item) continue
                item = JSON.parse(JSON.stringify(item))
                if (!item.name) item.name = '未命名'
                if (!uk) item.name += '（默认）'
                res.push(item)
            }
            return res
        }
    },
    data () {
        return {
            disabled: false,
            loaded: -1,
            userKeys: [''],
        }
    },
    mounted () {
        // 如果有默认选择的，加载默认选择的
        if (this.uk) connectStatus(this.connectId, this.uk).then(()=>{
            this.userKeys.push(this.uk)
        })
    },
    methods: {
        getName (item, uk) {
            let name = item && item.name || '未命名'
            if (!uk) name += '（默认）'
            return name
        },
        onFocus () {
            // 如果已经有数据
            if (this.loaded === 1) return
            // 如果没权限不足
            if (this.vuex_userLevel < this.info.action.userLevel) {
                this.$fs.toast('你还没有权限使用此连接。')
                return this.disabled = true
            }
            this.loaded = 0
            connectStatusAll(this.connectId).then(res=>{
                for (const uk of res) if (uk) this.userKeys.push(uk)
                this.userKeys = [].concat(this.userKeys)
                this.loaded = 1
            }).catch(err=>{
                this.$fs.toast(err.toast)
                this.loaded = -1
            })
        }
    }
}
</script>